// @import 'bootstrap/variables';

// utils
.can-click {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden-xl {
  @include media-breakpoint-only(xl) {
    display: none;
  }
}

.hidden-lg {
  @include media-breakpoint-only(lg) {
    display: none;
  }
}

.hidden-md {
  @include media-breakpoint-only(md) {
    display: none;
  }
}

.hidden-sm {
  @include media-breakpoint-only(sm) {
    display: none;
  }
}

.hidden-xs {
  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.modal-backdrop-light {
  background: map-get($map: $theme-colors, $key: light);
  opacity: 1;

  &.modal-backdrop.show {
    opacity: 1;
  }
}

.callout {
  position: relative;
  padding: 0 1rem;
  margin: 1rem 0;
  border-left: 4px solid #c8ced3;
  border-radius: .25rem;
}

//Forms  and callout colors
$fontColor: theme-color('secondary');

@each $theme, $color in $theme-colors {
  @if $color == theme-color('secondary') {
    $fontColor: black
  } 
  @else {
    $fontColor: theme-color('secondary')
  }
  .#{$theme}Form {
    background-color: $color;
    color: $fontColor
  }
  .callout-#{$theme} {
    border-left-color: $color
  }
}
.tr-white {
  background-color: white;
}
.btn-rounded {
  border-radius: 100%
}
.invoice-total {
  margin-left: 3px;
  margin-right: -1px;
}
.m-t-5 {
  margin-top:5px
}
.m-b-5 {
  margin-bottom:5px
}
.invoice-header {
  background: #F5F5F5;
  margin-bottom: 7px;
  margin-left: 3px;
  margin-right: 3px;
}
.clickable { 
  cursor: pointer;
}
.totalSubtotal {
  background: $lightPrideBlue;
}
.totalSubtotal {
  background: $lightPrideBlue;
}
.totalCost {
  background: $prideBlue;
  color: $lightPrideBlue;
}

.salesOrderPicker .DayPicker-Day--startDate {
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
  background-color: theme-color('info') !important;
  color: theme-color('secondary')
}
.salesOrderPicker .DayPicker-Day--dueDate {
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
  background-color: theme-color('info') !important;
  color: theme-color('secondary')
}
.salesOrderPicker .DayPicker-Day--deliveryStartDate {
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
  background-color: $DarkGreen !important;
  color: theme-color('secondary')
}
.salesOrderPicker .DayPicker-Day--deliveryDueDate {
  border-top-left-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
  background-color: $DarkGreen !important;
  color: theme-color('secondary')
}
.salesOrderPicker .DayPicker-Day--customsDeclarationDate {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  background-color: $DarkCayenne;
  color: theme-color('secondary')
}

.salesOrderPicker .DayPicker-Day--selectedDelivery:not(.DayPicker-Day--startDate):not(.DayPicker-Day--dueDate):not(.DayPicker-Day--outside):not(.DayPicker-Day--deliveryStartDate):not(.DayPicker-Day--deliveryDueDate) {
  background-color: $lightGreen;
  color: $DarkGreen;
  border-radius: 0 !important;
  &.DayPicker-Day--selectedEffectivity{
    background-color: $lightGreen;
    color: $DarkGreen;
    border-radius: 0 !important;
  }
}

.salesOrderPicker .DayPicker-Day--selectedEffectivity:not(.DayPicker-Day--startDate):not(.DayPicker-Day--dueDate):not(.DayPicker-Day--outside):not(.DayPicker-Day--deliveryStartDate):not(.DayPicker-Day--deliveryDueDate) {
  background-color: $lightBlue;
  color: $DarkBlue;
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--loadDate):not(.DayPicker-Day--shipDate) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.Selectable .DayPicker-Day--shipDate {
  background-color: theme-color('danger') !important;
}
.Selectable .DayPicker-Day--loadDate {
  background-color: $DarkGreen !important;
}
.badge-radius-right {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}
.badge-radius-left {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}
.form-date-control {
  width: 118%
}
.Dropzone {
  position: relative;
  border-width: 2px;
  border-color: theme-color('primary');
  border-style: dashed;
  border-radius: 5px;
}
.dzu-dropzone {
  overflow: hidden !important;
  min-height: 354px !important;
}