.cr-header {
  margin-bottom: $spacer * 2;
  height: 68px;
  border-bottom: $border-width solid $border-color;
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }
}
