.cr-widget {
  display: flex;
  flex-direction: row;

  &__icon {
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
  }
}

.primary-detail-panel {
  border-left: 3px;
  border-left-style: solid;
  border-left-color: theme-color("primary")
}
.success-detail-panel {
  border-left: 3px;
  border-left-style: solid;
  border-left-color: theme-color("success")
}
.danger-detail-panel {
  border-left: 3px;
  border-left-style: solid;
  border-left-color: theme-color("danger")
}
.warning-detail-panel {
  border-left: 3px;
  border-left-style: solid;
  border-left-color: theme-color("warning")
}
.secondary-detail-panel {
  border-left: 3px;
  border-left-style: solid;
  border-left-color: theme-color("secondary")
}
.info-detail-panel {
  border-left: 3px;
  border-left-style: solid;
  border-left-color: theme-color("info")
}
