/**
* Tyson official colors obtained from Tyson UI kit obtained from
* https://tysononline.sharepoint.com/sites/Source/ISCOE/Pages/UI%20Kit.aspx
*
*/

/**
* Primary Colors
*/
$lightPrideBlue : #EDF3F9;
$prideBlue: #002554;

/**
* Secondary colors
*/
$lightMustardSeed:#FFF2E0;
$MustardSeed:#F3AF00;
$DarkMustardSeed: #916600;

$lightSage:#EAEEEE;
$Sage:#809E9B;
$DarkSage: #506B67;

$lightCayenne:#FDE9E0;
$Cayenne:#EB3C31;
$DarkCayenne:#B02D2A;

$lightGray:#EDEDEE;
$Gray:#65686B;
$DarkGray:#53565A;

$lightGreen:#D7F7DD;
$Green:#4BD964;
$DarkGreen:#156523;

$lightBlue:#CDE7FE;
$Blue:#2699FB;
$DarkBlue:#024075;


/**
* Theme Colors
*/
$theme-colors: (
  "primary": #002554,
  "secondary": #EDF3F9,
  "success": #156523,
  "danger": #B02D2A,
  "warning": #F3AF00,
  "info": #024075
);

$enable-gradients: true;

$card-cap-bg: #fff;

$cr-sidebar-width: 224px;
